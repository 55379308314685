import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Avatar, Typography } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Message as MessageIcon, LockReset as ResetIcon } from '@mui/icons-material'; // Symbole importiert
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebase';  // Firebase-Import

const UserManagementTable = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  // Benutzer-Daten von Firestore laden (zusätzliche Daten wie Abo-Infos)
  const fetchUserData = async () => {
    try {
      const usersRef = collection(db, 'users');  // Sammlung der Benutzer in Firestore
      const userSnapshot = await getDocs(usersRef);
      const userList = userSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(userList);
    } catch (error) {
      console.error("Fehler beim Abrufen der Benutzerdaten:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  if (loading) {
    return <Typography variant="h6">Lade Benutzerdaten...</Typography>;
  }

  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: 'rgba(255, 255, 255, 0.4)', // Halbtransparenter weißer Hintergrund
        borderRadius: '8px', // Optional: Abgerundete Ecken für den Container
        minHeight: '100vh', // Der Hintergrund geht über die gesamte Höhe des Bildschirms
      }}
    >
      <TableContainer component={Paper} sx={{ border: `1px solid #d5bc8b`, backgroundColor: 'white' }}> {/* Goldener Rahmen und weißer Hintergrund */}
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#303e49', color: 'white', fontSize: '0.9rem' }}>Status</TableCell>
              <TableCell sx={{ backgroundColor: '#303e49', color: 'white', fontSize: '0.9rem' }}>Name</TableCell>
              <TableCell sx={{ backgroundColor: '#303e49', color: 'white', fontSize: '0.9rem' }}>Vorname</TableCell>
              <TableCell sx={{ backgroundColor: '#303e49', color: 'white', fontSize: '0.9rem' }}>Registrierungsdatum</TableCell>
              <TableCell sx={{ backgroundColor: '#303e49', color: 'white', fontSize: '0.9rem' }}>Laufzeit des Abos</TableCell>
              <TableCell sx={{ backgroundColor: '#303e49', color: 'white', fontSize: '0.9rem' }}>Paketart</TableCell>
              <TableCell sx={{ backgroundColor: '#303e49', color: 'white', fontSize: '0.9rem' }}>Kündigungsstatus</TableCell>
              <TableCell sx={{ backgroundColor: '#303e49', color: 'white', fontSize: '0.9rem' }}>Aktionen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow
                key={user.id}
                sx={{
                  '&:hover': {
                    backgroundColor: '#d5bc8b',  // Hover-Effekt: goldfarbener Hintergrund
                  },
                  height: 48,
                  borderBottom: `1px solid #d2d7dd`,  // Linienfarbe der Zeilen
                }}
              >
                {/* Online/Offline Status */}
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar sx={{ bgcolor: user.online ? 'green' : 'gray', width: 24, height: 24 }} /> {/* Online-/Offline-Status als grüner/grauer Kreis */}
                  </Box>
                </TableCell>

                {/* Benutzerinformationen */}
                <TableCell sx={{ fontSize: '0.9rem' }}>{user.lastName || 'Nicht verfügbar'}</TableCell>
                <TableCell sx={{ fontSize: '0.9rem' }}>{user.firstName || 'Nicht verfügbar'}</TableCell>

                {/* Registrierungsdatum */}
                <TableCell sx={{ fontSize: '0.9rem' }}>
                  {user.registrationDate
                    ? new Date(user.registrationDate.seconds * 1000).toLocaleDateString()
                    : 'Nicht verfügbar'}
                </TableCell>

                {/* Laufzeit des Abos */}
                <TableCell sx={{ fontSize: '0.9rem' }}>{user.subscriptionDuration || 'Nicht verfügbar'}</TableCell>

                {/* Art des Pakets */}
                <TableCell sx={{ fontSize: '0.9rem' }}>{user.packageType || 'Nicht verfügbar'}</TableCell>

                {/* Kündigungsstatus */}
                <TableCell sx={{ fontSize: '0.9rem' }}>
                  {user.cancellationStatus === 'active' && <Typography color="green">Aktiv</Typography>}
                  {user.cancellationStatus === 'pending' && <Typography color="orange">Gekündigt</Typography>}
                  {user.cancellationStatus === 'completed' && <Typography color="red">Abgeschlossen</Typography>}
                </TableCell>

                {/* Aktionen */}
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* Bearbeiten Button */}
                    <IconButton onClick={() => console.log("Bearbeiten")} color="primary">
                      <EditIcon sx={{ color: '#d5bc8b' }} />
                    </IconButton>

                    {/* Nachricht senden Button */}
                    <IconButton onClick={() => console.log("Nachricht senden")} color="inherit">
                      <MessageIcon sx={{ color: 'gray' }} /> {/* Symbol in Grau */}
                    </IconButton>

                    {/* Passwort zurücksetzen Button */}
                    <IconButton onClick={() => console.log("Passwort zurücksetzen")} color="inherit">
                      <ResetIcon sx={{ color: '#d5bc8b' }} /> {/* Symbol in Gold */}
                    </IconButton>

                    {/* Löschen Button */}
                    <IconButton onClick={() => console.log("Löschen")} color="secondary">
                      <DeleteIcon sx={{ color: '#d9534f', '&:hover': { color: 'white' } }} /> {/* Rot beim Löschen */}
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UserManagementTable;
