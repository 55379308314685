import React, { useState, useEffect } from 'react';
import { Box, ToggleButtonGroup, ToggleButton, Typography, CardContent } from '@mui/material';
import { keyframes } from '@emotion/react';

const QuestionComponent4 = ({ saveAnswer }) => {
  const [selected, setSelected] = useState([]);
  const [animateExit, setAnimateExit] = useState(false);

  const handleToggle = (event, newSelection) => {
    setSelected(newSelection);
    saveAnswer(newSelection);
  };

  const questionTitle = "Inwiefern darf Dein Pferd körperlich beansprucht werden?";
  const answers = [
    "Schritt", 
    "Trab", 
    "Galopp", 
    "enge Wendungen", 
    "seitliche Bewegung", 
    "Sprung", 
    "Kreisbahn"
  ];

  // Keyframes für die plötzliche Ein- und Ausgangsanimation ohne Skalierung
  const fadeIn = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `;

  const fadeOut = keyframes`
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  `;

  useEffect(() => {
    if (animateExit) {
      const timeout = setTimeout(() => {
        // Hier könnte der Schrittwechsel erfolgen
      }, 300); // 300ms entspricht der Dauer der Animation
      return () => clearTimeout(timeout);
    }
  }, [animateExit]);

  return (
    <Box
      sx={{
        mt: 2,
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '28px',
        animation: `${animateExit ? fadeOut : fadeIn} 0.3s ease-in-out both`, // Einfache 300ms-Ein- und Ausgangsanimation
      }}
    >
      <Typography variant="h6" sx={{ width: '100%', textAlign: 'center', mb: 3 }}>
        {questionTitle}
      </Typography>

      <ToggleButtonGroup
        value={selected}
        onChange={handleToggle}
        multiple
        sx={{ justifyContent: 'center', display: 'flex', flexWrap: 'wrap', gap: '28px' }}
      >
        {answers.map((answer) => (
          <ToggleButton
            key={answer}
            value={answer}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '240px',
              height: '300px',
              backgroundColor: selected.includes(answer) ? '#e2c391' : 'rgba(50, 50, 60, 0.8)',
              transition: 'background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease',
              boxShadow: selected.includes(answer) ? '0 0 0 2px #d5bc8b' : '0 2px 6px rgba(0, 0, 0, 0.3)',
              color: selected.includes(answer) ? '#d5bc8b' : '#fff',
              '&:hover': {
                transform: 'scale(1.02)',
                backgroundColor: selected.includes(answer) ? '#d5bc8b' : 'rgba(37, 40, 51, 0.85)',
                boxShadow: selected.includes(answer) ? '0 0 0 2px #d5bc8b' : '0 6px 12px rgba(0, 0, 0, 0.3)', // Goldener Rahmen bei Hover, wenn ausgewählt
              },
              '& .MuiTypography-root': {
                color: selected.includes(answer) ? '#d5bc8b' : '#fff',
              },
            }}
          >
            <Box 
              sx={{ 
                width: '100%', 
                height: '150px', 
                backgroundColor: '#ccc',
              }}
            />
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography 
                variant="subtitle2" 
                align="center" 
                sx={{ fontWeight: selected.includes(answer) ? 500 : 400, fontSize: '1rem' }}
              >
                {answer}
              </Typography>
            </CardContent>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
};

export default QuestionComponent4;
