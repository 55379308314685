import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage"; // Importiere Firebase Storage

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const realtimeDB = getDatabase(app);
const functions = getFunctions(app);
const storage = getStorage(app); // Speicher hinzufügen

// Persistenzmodus auf 'local' setzen, um eingeloggt zu bleiben
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Persistenzmodus ist 'local', der Login bleibt gespeichert.");
  })
  .catch((error) => {
    console.error("Fehler beim Setzen des Persistenzmodus: ", error);
  });

export { auth, db, realtimeDB, functions, storage }; // Exportiere storage
