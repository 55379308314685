import React from 'react';
import ReactDOM from 'react-dom/client'; // Importiere createRoot
import App from './App';
import { ThemeProvider } from '@mui/material/styles';  // ThemeProvider importieren
import CssBaseline from '@mui/material/CssBaseline';   // CssBaseline importieren
import theme from './theme';  // Dein benutzerdefiniertes Theme importieren
import { AuthProvider } from './components/pages/hooks/useAuth'; // Importiere den AuthProvider

const container = document.getElementById('root'); // Hole das Root-Element
const root = ReactDOM.createRoot(container); // Verwende createRoot statt ReactDOM.render

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>  {/* ThemeProvider um App wickeln */}
      <AuthProvider> {/* AuthProvider um die gesamte App wickeln */}
        <CssBaseline />  {/* CssBaseline sorgt für globale Standardstile */}
        <App />
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);
