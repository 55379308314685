import React, { useState } from 'react';
import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { ReactComponent as HorseIcon } from '../../../assets/icons/horse.svg';
import PersonIcon from '@mui/icons-material/Person';
import EditUserProfile from './UserProfile_components/EditUserProfile';
import MyHorses from './UserProfile_components/MyHorses';
import ProfileImageUploader from './UserProfile_components/utils/ProfileImageUploader';

const drawerWidth = 240;

const DrawerContainer = styled(Box)({
  display: 'flex',
});

const AnimatedListItem = styled(({ selected, ...other }) => (
  <ListItem {...other} />
))(({ selected }) => ({
  margin: '4px 0',
  transition: '0.3s',
  backgroundColor: selected ? 'transparent' : 'transparent',
  color: selected ? '#d5bc8b' : 'inherit',
  '&:hover': {
    color: '#d5bc8b',
    transform: 'scale(1.05)',
  },
}));

const UserProfile = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [content, setContent] = useState(<EditUserProfile />); // Default-Inhalt
  const [avatarUrl, setAvatarUrl] = useState('/path/to/default/profile/picture.jpg'); // Default Avatar URL
  const [imageUploaderOpen, setImageUploaderOpen] = useState(false); // State für den Image Uploader

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    switch (index) {
      case 0:
        setContent(<EditUserProfile avatarUrl={avatarUrl} onImageUploaderOpen={() => setImageUploaderOpen(true)} />);
        break;
      case 1:
        setContent(<MyHorses />);
        break;
      default:
        setContent(<EditUserProfile avatarUrl={avatarUrl} onImageUploaderOpen={() => setImageUploaderOpen(true)} />);
    }
  };

  const handleImageUpload = (url) => {
    setAvatarUrl(url); // Aktualisiere den Avatar-URL
    setImageUploaderOpen(false); // Schließe den Image Uploader
  };

  const drawer = (
    <div>
      <Divider />
      <List>
        <AnimatedListItem
          button
          selected={selectedIndex === 0}
          onClick={(event) => handleListItemClick(event, 0)}
        >
          <ListItemIcon>
            <PersonIcon style={{ stroke: '#d5bc8b', fill: 'none', strokeWidth: 2 }} />
          </ListItemIcon>
          <ListItemText primary="Mein Profil" />
        </AnimatedListItem>
        <AnimatedListItem
          button
          selected={selectedIndex === 1}
          onClick={(event) => handleListItemClick(event, 1)}
        >
          <ListItemIcon>
            <HorseIcon style={{ stroke: '#d5bc8b', fill: 'none' }} />
          </ListItemIcon>
          <ListItemText primary="Meine Pferde" />
        </AnimatedListItem>
      </List>
      <Divider />
    </div>
  );

  return (
    <DrawerContainer
      sx={{
        backgroundImage: `url('/images/backgrounds/kth_fasern.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        minHeight: '100vh',
      }}
    >
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
            marginTop: '79px',
            backgroundColor: '#252833',
            color: '#fff',
          },
        }}
      >
        {drawer}
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {content}
      </Box>

      <ProfileImageUploader 
        open={imageUploaderOpen} 
        onClose={() => setImageUploaderOpen(false)} 
        onImageUpload={handleImageUpload} 
      />
    </DrawerContainer>
  );
};

export default UserProfile;
