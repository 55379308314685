import React from 'react';
import VideoUploadFromJson from '../../functions/VideoUploadFromJson'; // Stelle sicher, dass der Pfad korrekt ist

const JsonImport = () => {
  return (
    <div>
      <h1>JSON Video-Import Seite</h1>
      <VideoUploadFromJson />
    </div>
  );
};

export default JsonImport; // Standardexport
