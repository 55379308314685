import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Tooltip,
  Snackbar,
  Alert,
} from '@mui/material';
import {
  Edit,
  Delete,
  CheckCircle,
  Cancel,
  ShoppingCart,
  ContentCopy,
  Preview,
} from '@mui/icons-material';
import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
} from 'firebase/firestore';
import { db } from '../../config/firebase'; // Passe den Pfad entsprechend an

import CountryFlag from 'react-country-flag';

const countryOptions = [
  { code: 'DE', label: 'Deutschland' },
  { code: 'AT', label: 'Österreich' },
  { code: 'CH', label: 'Schweiz' },
  // Weitere Länder hinzufügen
];

function ProductManagement() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const [currentProduct, setCurrentProduct] = useState({
    id: '',
    name: '',
    price: '',
    currency: 'EUR',
    interval: '',
    times: '',
    autoRenew: true,
    isActive: true,
    showOnWebsite: true,
    countries: [],
    expirationDate: '',
    description: '',
  });

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const productsCollection = collection(db, 'products');
      const productSnapshot = await getDocs(productsCollection);
      const productList = productSnapshot.docs.map((docItem) => {
        const data = docItem.data();
        return {
          id: docItem.id,
          ...data,
          countries: data.countries
            ? data.countries.map(
                (code) =>
                  countryOptions.find((c) => c.code === code) || {
                    code,
                    label: code,
                  }
              )
            : [],
          expirationDate: data.expirationDate
            ? new Date(data.expirationDate.seconds * 1000)
                .toISOString()
                .split('T')[0]
            : '',
        };
      });
      setProducts(productList);
    } catch (error) {
      console.error('Fehler beim Abrufen der Produkte:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const initializeProductForm = (product = null) => {
    if (product) {
      setIsEditMode(true);
      setCurrentProduct({
        id: product.id,
        name: product.name,
        price: product.price,
        currency: product.currency,
        interval: product.interval,
        times: product.times,
        autoRenew: product.autoRenew,
        isActive: product.isActive,
        showOnWebsite: product.showOnWebsite,
        countries: product.countries,
        expirationDate: product.expirationDate || '',
        description: product.description,
      });
    } else {
      setIsEditMode(false);
      setCurrentProduct({
        id: '',
        name: '',
        price: '',
        currency: 'EUR',
        interval: '',
        times: '',
        autoRenew: true,
        isActive: true,
        showOnWebsite: true,
        countries: [],
        expirationDate: '',
        description: '',
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSaveProduct = async () => {
    const { price, times, expirationDate } = currentProduct;

    // Validierung der Eingabefelder
    if (!currentProduct.name) {
      setSnackbarMessage('Der Name des Produkts ist erforderlich.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }
    if (isNaN(price) || price <= 0) {
      setSnackbarMessage('Bitte geben Sie einen gültigen Preis ein.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }
    if (isNaN(times) || times <= 0) {
      setSnackbarMessage('Bitte geben Sie eine gültige Laufzeit ein.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }
    if (expirationDate && isNaN(new Date(expirationDate).getTime())) {
      setSnackbarMessage('Das Ablaufdatum ist ungültig.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    try {
      const productData = {
        name: currentProduct.name,
        price: parseFloat(currentProduct.price),
        currency: currentProduct.currency,
        interval: currentProduct.interval,
        times: parseInt(currentProduct.times),
        autoRenew: currentProduct.autoRenew,
        isActive: currentProduct.isActive,
        showOnWebsite: currentProduct.showOnWebsite,
        countries: currentProduct.countries
          ? currentProduct.countries.map((c) => c.code)
          : [],
        expirationDate: currentProduct.expirationDate
          ? new Date(currentProduct.expirationDate)
          : null,
        description: currentProduct.description,
      };

      if (isEditMode) {
        const productRef = doc(db, 'products', currentProduct.id);
        await updateDoc(productRef, productData);
      } else {
        await addDoc(collection(db, 'products'), productData);
      }
      fetchProducts();
      handleCloseDialog();
      setSnackbarMessage('Produkt erfolgreich gespeichert.');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Fehler beim Speichern des Produkts:', error);
      setSnackbarMessage('Fehler beim Speichern des Produkts.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleDeleteProduct = async (id) => {
    try {
      await deleteDoc(doc(db, 'products', id));
      fetchProducts();
      setSnackbarMessage('Produkt erfolgreich gelöscht.');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Fehler beim Löschen des Produkts:', error);
      setSnackbarMessage('Fehler beim Löschen des Produkts.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const generateCheckoutLink = (productId) => {
    return `https://us-central1-dein-projekt.cloudfunctions.net/createCheckout?productId=${productId}`;
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        alert('Link wurde in die Zwischenablage kopiert.');
      },
      (err) => {
        console.error('Kopieren fehlgeschlagen:', err);
      }
    );
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Produktverwaltung
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => initializeProductForm()}
        sx={{ marginBottom: 2 }}
      >
        Neues Produkt erstellen
      </Button>
      {loading ? (
        <Typography>Lädt...</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Preis</TableCell>
                <TableCell>Abbuchungs-Intervall</TableCell>
                <TableCell>Laufzeit</TableCell>
                <TableCell>Autom. verlängern</TableCell>
                <TableCell>Aktiv</TableCell>
                <TableCell>Auf Webseite</TableCell>
                <TableCell>Ablaufdatum</TableCell>
                <TableCell>Beschreibung</TableCell>
                <TableCell>Länder</TableCell>
                <TableCell>Links</TableCell>
                <TableCell>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product) => (
                <TableRow key={product.id}>
                  <TableCell>
                    <ShoppingCart />
                  </TableCell>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>
                    {product.price} {product.currency}
                  </TableCell>
                  <TableCell>{product.interval}</TableCell>
                  <TableCell>{product.times}</TableCell>
                  <TableCell>
                    {product.autoRenew ? (
                      <CheckCircle color="success" />
                    ) : (
                      <Cancel color="error" />
                    )}
                  </TableCell>
                  <TableCell>
                    {product.isActive ? (
                      <CheckCircle color="success" />
                    ) : (
                      <Cancel color="error" />
                    )}
                  </TableCell>
                  <TableCell>
                    {product.showOnWebsite ? (
                      <CheckCircle color="success" />
                    ) : (
                      <Cancel color="error" />
                    )}
                  </TableCell>
                  <TableCell>
                    {product.expirationDate
                      ? new Date(product.expirationDate).toLocaleDateString()
                      : 'Kein Ablauf'}
                  </TableCell>
                  <TableCell>{product.description}</TableCell>
                  <TableCell>
                    {product.countries &&
                      product.countries.map((country) => (
                        <CountryFlag
                          key={country.code}
                          countryCode={country.code}
                          svg
                          style={{ fontSize: '1.5em', marginRight: '5px' }}
                        />
                      ))}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Link kopieren">
                      <IconButton
                        onClick={() =>
                          copyToClipboard(generateCheckoutLink(product.id))
                        }
                      >
                        <ContentCopy />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Checkout-Vorschau">
                      <IconButton
                        onClick={() =>
                          window.open(generateCheckoutLink(product.id), '_blank')
                        }
                      >
                        <Preview />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => initializeProductForm(product)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteProduct(product.id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Dialog zum Erstellen/Bearbeiten von Produkten */}
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth>
        <DialogTitle>
          {isEditMode ? 'Produkt bearbeiten' : 'Neues Produkt erstellen'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={currentProduct.name}
            onChange={(e) =>
              setCurrentProduct({ ...currentProduct, name: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Preis"
            type="number"
            fullWidth
            value={currentProduct.price}
            onChange={(e) =>
              setCurrentProduct({ ...currentProduct, price: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Währung"
            fullWidth
            value={currentProduct.currency}
            onChange={(e) =>
              setCurrentProduct({ ...currentProduct, currency: e.target.value })
            }
          />

          <FormControl fullWidth margin="dense">
            <InputLabel id="interval-label">Abbuchungs-Intervall</InputLabel>
            <Select
              labelId="interval-label"
              value={currentProduct.interval}
              label="Abbuchungs-Intervall"
              onChange={(e) =>
                setCurrentProduct({
                  ...currentProduct,
                  interval: e.target.value,
                })
              }
            >
              <MenuItem value="1 week">Wöchentlich</MenuItem>
              <MenuItem value="1 month">Monatlich</MenuItem>
              <MenuItem value="3 months">3-monatlich</MenuItem>
              <MenuItem value="6 months">Halbjährlich</MenuItem>
              <MenuItem value="1 year">Jährlich</MenuItem>
            </Select>
          </FormControl>

          <TextField
            margin="dense"
            label="Laufzeit (Anzahl der Abbuchungen)"
            type="number"
            fullWidth
            value={currentProduct.times}
            onChange={(e) =>
              setCurrentProduct({ ...currentProduct, times: e.target.value })
            }
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={currentProduct.autoRenew}
                onChange={(e) =>
                  setCurrentProduct({
                    ...currentProduct,
                    autoRenew: e.target.checked,
                  })
                }
              />
            }
            label="Automatisch verlängern"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={currentProduct.isActive}
                onChange={(e) =>
                  setCurrentProduct({
                    ...currentProduct,
                    isActive: e.target.checked,
                  })
                }
              />
            }
            label="Produkt ist aktiv"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={currentProduct.showOnWebsite}
                onChange={(e) =>
                  setCurrentProduct({
                    ...currentProduct,
                    showOnWebsite: e.target.checked,
                  })
                }
              />
            }
            label="Auf Verkaufswebseite anzeigen"
          />

          <Autocomplete
            multiple
            options={countryOptions}
            getOptionLabel={(option) => option.label}
            value={currentProduct.countries}
            onChange={(event, newValue) => {
              setCurrentProduct({
                ...currentProduct,
                countries: newValue,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Verfügbare Länder"
                margin="dense"
                fullWidth
              />
            )}
          />

          <TextField
            margin="dense"
            label="Ablaufdatum (optional)"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={currentProduct.expirationDate}
            onChange={(e) =>
              setCurrentProduct({
                ...currentProduct,
                expirationDate: e.target.value,
              })
            }
          />

          <TextField
            margin="dense"
            label="Beschreibung"
            fullWidth
            multiline
            rows={3}
            value={currentProduct.description}
            onChange={(e) =>
              setCurrentProduct({
                ...currentProduct,
                description: e.target.value,
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Abbrechen</Button>
          <Button onClick={handleSaveProduct} variant="contained" color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default ProductManagement;
