import React, { useEffect } from 'react';


// Importiere Axios für API-Requests
import axios from 'axios';

const ZoomAdminPanel = () => {

  // Umgebungsvariablen laden
  const clientId = process.env.REACT_APP_ZOOM_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_ZOOM_CLIENT_SECRET;
  const accountId = process.env.REACT_APP_ZOOM_ACCOUNT_ID;

  const getAccessToken = async () => {
    try {
      // Zoom OAuth-Token API-Aufruf
      const response = await axios.post(
        `https://zoom.us/oauth/token?grant_type=account_credentials&account_id=${accountId}`,
        {},
        {
          headers: {
            Authorization: `Basic ${btoa(`${clientId}:${clientSecret}`)}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );

      // Zugriffstoken aus der Antwort
      const accessToken = response.data.access_token;
      return accessToken;
    } catch (error) {
      console.error('Fehler beim Abrufen des Zugriffstokens:', error);
      return null;
    }
  };

  const startMeeting = async () => {
    const token = await getAccessToken();

    if (token) {
      // Hier würdest du das Zoom Meeting API aufrufen, um das Meeting zu starten
      axios.post(
        'https://api.zoom.us/v2/users/me/meetings',
        {
          topic: 'Admin Meeting',
          type: 1, // Sofort-Meeting
          settings: {
            host_video: true,
            participant_video: false,
          }
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        console.log('Meeting erfolgreich erstellt:', response.data);
      })
      .catch((error) => {
        console.error('Fehler beim Erstellen des Meetings:', error);
      });
    }
  };

  useEffect(() => {
    startMeeting(); // Meeting bei Komponenteneinbindung starten
  }, []);

  return (
    <div>
      <h1>Zoom Admin Panel</h1>
      {/* Zoom Meeting wird in diesem Container dargestellt */}
      <div id="zmmtg-root"></div>
    </div>
  );
};

export default ZoomAdminPanel;
