import { useEffect, useState, useContext, createContext } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Erstelle den AuthContext
const AuthContext = createContext();

// Der Provider stellt die Auth-Daten für die App bereit
export function AuthProvider({ children }) {
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, [auth]);

  return (
    <AuthContext.Provider value={{ user, loading }}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

// Custom Hook, um den aktuellen Benutzer zu erhalten
export function useAuth() {
  return useContext(AuthContext);
}
