import React, { useRef, useState } from 'react';
import { Modal, Button, Box, Typography, Slider } from '@mui/material';
import Cropme from 'cropme';
import 'cropme/dist/cropme.min.css';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadString } from 'firebase/storage';
import { updateDoc, doc } from 'firebase/firestore';
import { db } from '../../../../../config/firebase';

const ProfileImageUploader = ({ open, onClose }) => {
  const [cropper, setCropper] = useState(null);
  const containerRef = useRef(null);
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      initializeCropper(url);
    }
  };

  const initializeCropper = (url) => {
    if (cropper) cropper.destroy();
    const newCropper = new Cropme(containerRef.current, {
      viewport: {
        width: 150,
        height: 150,
        type: 'circle',
        border: {
          enable: true,
          width: 2,
          color: '#fff',
        },
      },
      zoom: {
        enable: true,
        slider: false,
        mouseWheel: true,
      },
      rotation: {
        enable: true,
        slider: true,
        position: 'right',
      },
    });

    newCropper.bind({ url }).then(() => {
      console.log('Image successfully bound to Cropme.');
    }).catch((error) => {
      console.error('Error binding image to Cropme:', error);
    });

    setCropper(newCropper);
  };

  const handleZoomChange = (event, newValue) => {
    setZoom(newValue);
    if (cropper) {
      cropper.properties.scale = newValue;
      cropper.properties.image.style.transform = `scale(${newValue})`;
    }
  };

  const handleRotationChange = (event, newValue) => {
    setRotation(newValue);
    if (cropper) {
      cropper.rotate(newValue);
    }
  };

  const handleSave = async () => {
    if (cropper) {
      try {
        const base64 = await cropper.crop();
        const user = getAuth().currentUser;
        const storage = getStorage();
        const storageRef = ref(storage, `profilePictures/${user.uid}/ProfilePicture_${user.uid}.jpg`);
        
        // Bild im Firebase Storage speichern
        await uploadString(storageRef, base64, 'data_url');
        console.log('Image uploaded to Firebase Storage.');

        // Speicherort in der Firestore-Datenbank aktualisieren
        const userDocRef = doc(db, 'users', user.uid);
        await updateDoc(userDocRef, {
          profilePicturePath: `profilePictures/${user.uid}/ProfilePicture_${user.uid}.jpg`
        });
        console.log('Profile picture path updated in Firestore.');

        onClose();
      } catch (error) {
        console.error('Error cropping or uploading image:', error);
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 2,
          backgroundColor: '#252833',
          borderRadius: '8px',
          boxShadow: 24,
          opacity: 0.9,
          border: '1px solid #d5bc8b',
          maxWidth: '500px',
          maxHeight: '700px', // Maximale Höhe erhöht
          width: '90%',
          height: 'auto',
          margin: 'auto',
        }}
      >
        <Typography variant="h6" sx={{ color: '#d5bc8b', mb: 2 }}>
          Profilbild bearbeiten
        </Typography>
        
        <Button variant="outlined" component="label" sx={{ color: '#d5bc8b', mb: 2 }}>
          Bild auswählen
          <input type="file" accept="image/*" hidden onChange={handleImageChange} />
        </Button>
        
        <div ref={containerRef} style={{ width: '300px', height: '300px', marginTop: '16px' }}></div>

        {/* Zoom Slider */}
        <Typography variant="body2" sx={{ color: '#d5bc8b', mt: 2 }}>Zoom</Typography>
        <Slider
          value={zoom}
          min={0.01}
          max={3}
          step={0.01}
          onChange={handleZoomChange}
          sx={{ width: '80%', mt: 1, color: '#d5bc8b' }} // Goldene Akzentfarbe für den Slider
        />

        {/* Rotation Slider */}
        <Typography variant="body2" sx={{ color: '#d5bc8b', mt: 2 }}>Rotation</Typography>
        <Slider
          value={rotation}
          min={-180}
          max={180}
          step={1}
          onChange={handleRotationChange}
          sx={{ width: '80%', mt: 1, color: '#d5bc8b' }} // Goldene Akzentfarbe für den Slider
        />

        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '80%', mt: 2 }}>
          <Button onClick={onClose} variant="outlined" sx={{ color: '#d5bc8b' }}>
            Abbrechen
          </Button>
          <Button onClick={handleSave} variant="contained" sx={{ backgroundColor: '#d5bc8b', color: 'black' }}>
            Speichern
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProfileImageUploader;
