import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth'; // Importiere Firebase-Auth
import Stack from '@mui/material/Stack'; // Importiere Material-UI Stack
import Box from '@mui/material/Box'; // Importiere Material-UI Box
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  return (
    <div
      style={{
        backgroundImage: `url('/images/backgrounds/kth_fasern.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '3240px', // Vollbildhöhe
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingTop: '20px', // Abstand nach oben
        color: 'white',
      }}
    >
      {/* Stack-Container für die Anordnung der Elemente */}
      <Stack 
        spacing={2} // Abstand zwischen den Elementen
        direction="column" // Vertikale Anordnung der Elemente
        sx={{ width: '100%', maxWidth: '1600px', alignItems: 'center' }} // Max. Breite von 1600px und Zentrierung
      >
        {/* Bild ganz oben */}
        <Box
          component="img"
          src="/images/hero/261A9054-min.jpg"  // Pfad zum Bild
          alt="Hero Image"
          sx={{
            width: '100%', // Bild füllt den gesamten Container
            height: '300px',  // Höhe des Bildes auf 300px setzen
            objectFit: 'cover',  // Bild zuschneiden, um es vollständig einzupassen
            objectPosition: 'center 45%',  // Zeige den mittleren Teil des Bildes
            borderRadius: '8px',  // Leichte Abrundung der Ecken
          }}
        />

        {/* Grid-Komponenten für die 3 Logos */}
        <Grid container spacing={4} sx={{ maxWidth: '1600px', width: '100%', justifyContent: 'center' }}>
          {/* Erstes Element mit Bild, Titel und Beschreibung: Lernplattform */}
          <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              sx={{
                width: '100%',
                maxWidth: '200px',
                textAlign: 'center',
                transition: 'transform 0.3s ease',
                '&:hover': { transform: 'scale(1.05)' },
              }}
            >
              {/* Bildcontainer mit fester Höhe */}
              <Box
                sx={{
                  height: '180px',  // Feste Höhe für alle Bilder
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box
                  component="img"
                  src="/images/logos/kth_logo_learning.png"
                  alt="KTH Learning"
                  sx={{
                    width: '120px', // Bildgröße des ersten Bildes
                    height: 'auto',
                    objectFit: 'contain', // Bildgröße proportional halten
                    borderRadius: '8px',
                  }}
                />
              </Box>
              <Typography variant="h5" sx={{ mt: 1 }}>
                Lernplattform
              </Typography>
              <Typography variant="body2" sx={{ mt: 1, mb:1 }}>
                Bilde dich für dein Pferd weiter!
              </Typography>
            </Box>
          </Grid>

          {/* Zweites Element mit Bild, Titel und Beschreibung: Community */}
          <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              sx={{
                width: '100%',
                maxWidth: '200px',
                textAlign: 'center',
                transition: 'transform 0.3s ease',
                '&:hover': { transform: 'scale(1.05)' },
              }}
            >
              {/* Bildcontainer mit fester Höhe */}
              <Box
                sx={{
                  height: '180px',  // Feste Höhe für alle Bilder
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box
                  component="img"
                  src="/images/logos/kth_logo_community.png"
                  alt="KTH Community"
                  sx={{
                    width: '180px', // Bildgröße der anderen beiden Bilder
                    height: 'auto',
                    objectFit: 'contain', // Bildgröße proportional halten
                    borderRadius: '8px',
                  }}
                />
              </Box>
              <Typography variant="h5" sx={{ mt: 1 }}>
                Community
              </Typography>
              <Typography variant="body2" sx={{ mt: 1, mb:1 }}>
                Tausche dich mit anderen Mitgliedern aus
              </Typography>
            </Box>
          </Grid>

          {/* Drittes Element mit Bild, Titel und Beschreibung Live-Meeting*/}
          <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              sx={{
                width: '100%',
                maxWidth: '200px',
                textAlign: 'center',
                transition: 'transform 0.3s ease',
                '&:hover': { transform: 'scale(1.05)' },
              }}
            >
              {/* Bildcontainer mit fester Höhe */}
              <Box
                sx={{
                  height: '180px',  // Feste Höhe für alle Bilder
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box
                  component="img"
                  src="/images/logos/kth_logo_livecall.png"
                  alt="KTH Live Call"
                  sx={{
                    width: '180px', // Bildgröße der anderen beiden Bilder
                    height: 'auto',
                    objectFit: 'contain', // Bildgröße proportional halten
                    borderRadius: '8px',
                  }}
                />
              </Box>
              <Typography variant="h5" sx={{ mt: 1, mb:1 }} >
                Live-Meeting
              </Typography>
              <Typography variant="body2" sx={{ mt: 1, mb:1 }}>
                Das nächste Live-Meeting findet am 19.10 statt
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Optional: Button unter den Elementen */}
        <Button variant="contained" color="primary" sx={{ mt: 4 }}>
          Weitere Aktionen
        </Button>
      </Stack>
    </div>
  );
};

export default Dashboard;
