import React, { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';

// Firebase-Konfiguration aus der .env-Datei laden
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Firebase initialisieren
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const FirebaseExample = () => {
  const [inputData, setInputData] = useState('');
  const [outputData, setOutputData] = useState('');

  const writeToFirestore = async () => {
    if (inputData) {
      try {
        await setDoc(doc(db, "users", "user2"), {
          data: inputData
        });
        alert("Daten erfolgreich geschrieben!");
      } catch (error) {
        console.error("Fehler beim Schreiben der Daten: ", error);
        alert("Fehler beim Schreiben der Daten");
      }
    } else {
      alert("Bitte geben Sie Daten ein!");
    }
  };

  const readFromFirestore = async () => {
    const docRef = doc(db, "users", "user1");
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data().data;
        setOutputData(data);
      } else {
        alert("Keine Daten gefunden!");
      }
    } catch (error) {
      console.error("Fehler beim Lesen der Daten: ", error);
      alert("Fehler beim Lesen der Daten");
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Firebase Firestore: Daten schreiben und lesen</h1>

      <div>
        <input
          type="text"
          value={inputData}
          onChange={(e) => setInputData(e.target.value)}
          placeholder="Daten eingeben"
          style={{ padding: '10px', marginRight: '10px' }}
        />
        <button onClick={writeToFirestore} style={{ padding: '10px 20px' }}>
          Daten schreiben
        </button>
      </div>

      <div style={{ marginTop: '20px' }}>
        <p>{outputData || "Daten werden hier angezeigt..."}</p>
        <button onClick={readFromFirestore} style={{ padding: '10px 20px' }}>
          Daten lesen
        </button>
      </div>
    </div>
  );
};

export default FirebaseExample;

