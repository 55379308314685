import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Divider, Typography } from '@mui/material';
import VideoSlider from '../../video/VideoSlider';
import { getFirestore, collection, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../hooks/useAuth';
// TabPanel-Komponente zur Steuerung der Tab-Inhalte
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

// a11y props zur besseren Bedienbarkeit
function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const VideoStage = () => {
  const [value, setValue] = useState(0);
  const [allVideos, setAllVideos] = useState([]);
  const [bookmarkedVideos, setBookmarkedVideos] = useState([]);
  const { user } = useAuth(); // Custom Hook zur Authentifizierung

  // Tab-Änderungen behandeln
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Videos aus Firestore laden
  useEffect(() => {
    const fetchVideos = async () => {
      const db = getFirestore();
      const videoCollection = collection(db, 'videos');
      try {
        const videoSnapshot = await getDocs(videoCollection);
        if (!videoSnapshot.empty) {
          const videoList = videoSnapshot.docs.map((doc) => ({
            id: doc.id,
            title: doc.data().titel,
            description: doc.data().text,
            thumbnail: doc.data().thumbnailUrl || 'https://via.placeholder.com/300x180.png?text=Video+Thumbnail',
          }));
          setAllVideos(videoList);
        } else {
          console.log('Keine Videos in der Datenbank gefunden.');
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Videos: ', error);
      }
    };

    fetchVideos();
  }, []);

  // Lesezeichen des Benutzers aus Firestore laden
  useEffect(() => {
    const fetchBookmarks = async () => {
      if (user) {
        const db = getFirestore();
        const userDocRef = doc(db, 'users', user.uid);

        try {
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setBookmarkedVideos(userData.bookmarks || []); // Falls keine Lesezeichen existieren, leere Liste setzen
            console.log('Lesezeichen des Benutzers abgerufen:', userData.bookmarks);
          }
        } catch (error) {
          console.error('Fehler beim Abrufen der Lesezeichen:', error);
        }
      }
    };

    fetchBookmarks();
  }, [user]); // Läuft, wenn der Benutzer sich ändert

  // Lesezeichen für ein Video umschalten
  const handleBookmarkToggle = async (video) => {
    if (!user) {
      console.error('Kein Benutzer angemeldet. Lesezeichen können nicht gespeichert werden.');
      return;
    }

    const db = getFirestore();
    const userDocRef = doc(db, 'users', user.uid);

    // Kopiere das aktuelle Lesezeichen-Array
    let updatedBookmarks = [...bookmarkedVideos];

    // Prüfen, ob das Video bereits in den Lesezeichen enthalten ist
    const isBookmarked = bookmarkedVideos.includes(video.id);

    if (isBookmarked) {
      // Entferne das Lesezeichen, wenn es bereits vorhanden ist
      updatedBookmarks = updatedBookmarks.filter((v) => v !== video.id);
    } else {
      // Füge das Video zu den Lesezeichen hinzu
      updatedBookmarks.push(video.id);
    }

    try {
      // Firestore-Dokument aktualisieren
      await updateDoc(userDocRef, {
        bookmarks: updatedBookmarks, // Speichere nur die Video-IDs
      });

      // Lokale State-Aktualisierung der Lesezeichen
      setBookmarkedVideos(updatedBookmarks);

      console.log('Lesezeichen erfolgreich aktualisiert.');
    } catch (error) {
      console.error('Fehler beim Aktualisieren der Lesezeichen in Firestore:', error);
    }
  };

  // Videos in Gruppen von 10 aufteilen
  const chunkVideos = (videos, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < videos.length; i += chunkSize) {
      chunks.push(videos.slice(i, i + chunkSize));
    }
    return chunks;
  };

  // Alle Videos in Gruppen von 10 aufteilen
  const videoChunks = chunkVideos(allVideos, 10);

  // Lesezeichen-Videos filtern
  const bookmarkedVideoList = allVideos.filter((video) =>
    bookmarkedVideos.includes(video.id)
  );

  // Videos mit Lesezeichen in Gruppen von 10 aufteilen
  const bookmarkedChunks = chunkVideos(bookmarkedVideoList, 10);

  return (
    <Box
      sx={{
        backgroundImage: 'url(/images/backgrounds/kth_fasern.png)',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        minHeight: '100vh',
        padding: '20px',
        overflowY: 'scroll',
      }}
    >
      <Typography
        variant="h3"
        sx={{
          textAlign: 'center',
          color: '#d6bc8b', // Goldene Farbe für die Überschrift
          mb: 4,
        }}
      >
        Lernplattform
      </Typography>

      {/* TabControl */}
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Video Tabs"
        sx={{
          borderBottom: '2px solid #3f4b54', // Trennstrich unter dem TabControl
          mb: 4,
          '& .MuiTabs-indicator': {
            backgroundColor: '#d6bc8b', // Goldene Linie unter dem aktiven Tab
          },
        }}
        TabIndicatorProps={{
          style: { height: '3px' }, // Unterstrich des aktiven Tabs
        }}
      >
        <Tab
          label="Alle Videos"
          {...a11yProps(0)}
          sx={{
            color: value === 0 ? '#d6bc8b' : '#c0c0c0', // Gold für aktiven Tab, Weiß für inaktiven
            fontWeight: value === 0 ? 'normal' : 'normal', // Nicht fett für aktiven Tab
            '&:hover': {
              color: value === 0 ? '#e0c890' : '#ffffff', // Hellgold beim Hover auf aktiv, Weiß für inaktiven
            },
            minWidth: 100,  // Hier die Mindestbreite setzen
          }}
        />
        <Tab
          label="Meine Videos"
          {...a11yProps(1)}
          sx={{
            color: value === 1 ? '#d6bc8b' : '#c0c0c0', // Gold für aktiven Tab, Weiß für inaktiven
            fontWeight: value === 1 ? 'normal' : 'normal', // Nicht fett für aktiven Tab
            '&:hover': {
              color: value === 1 ? '#e0c890' : '#ffffff', // Hellgold beim Hover auf aktiv, Weiß für inaktiven
            },
            minWidth: 100,  // Hier die Mindestbreite setzen
          }}
        />
        <Tab
          label="Lesezeichen"
          {...a11yProps(2)}
          sx={{
            color: value === 2 ? '#d6bc8b' : '#c0c0c0', // Gold für aktiven Tab, Weiß für inaktiven
            fontWeight: value === 2 ? 'normal' : 'normal', // Nicht fett für aktiven Tab
            '&:hover': {
              color: value === 2 ? '#e0c890' : '#ffffff', // Hellgold beim Hover auf aktiv, Weiß für inaktiven
            },
            minWidth: 100,  // Hier die Mindestbreite setzen
          }}
        />
      </Tabs>

      {/* Inhalt von Tab 1 - Alle Videos */}
      <TabPanel value={value} index={0}>
        <Box>
          <Typography>Hier können alle Videos angezeigt werden.</Typography>
        </Box>
      </TabPanel>

      {/* Inhalt von Tab 2 - Meine Videos */}
      <TabPanel value={value} index={1}>
        <Box>
          {/* VideoSlider-Komponenten */}
          {videoChunks[0] ? (
            <VideoSlider
              videos={videoChunks[0]}
              category="Kategorie 1"
              bookmarkedVideos={bookmarkedVideos}
              onBookmarkToggle={handleBookmarkToggle}
            />
          ) : (
            <Typography>Keine Videos in Kategorie 1</Typography>
          )}
          <Divider sx={{ backgroundColor: '#3f4b54', my: 4 }} />

          {videoChunks[1] ? (
            <VideoSlider
              videos={videoChunks[1]}
              category="Kategorie 2"
              bookmarkedVideos={bookmarkedVideos}
              onBookmarkToggle={handleBookmarkToggle}
            />
          ) : (
            <Typography>Keine Videos in Kategorie 2</Typography>
          )}
          <Divider sx={{ backgroundColor: '#3f4b54', my: 4 }} />

          {videoChunks[2] ? (
            <VideoSlider
              videos={videoChunks[2]}
              category="Kategorie 3"
              bookmarkedVideos={bookmarkedVideos}
              onBookmarkToggle={handleBookmarkToggle}
            />
          ) : (
            <Typography>Keine Videos in Kategorie 3</Typography>
          )}
        </Box>
      </TabPanel>

      {/* Inhalt von Tab 3 - Lesezeichen */}
      <TabPanel value={value} index={2}>
        <Box>
          {/* VideoSlider für Lesezeichen */}
          {bookmarkedChunks.length > 0 ? (
            bookmarkedChunks.map((chunk, index) => (
              <Box key={index}>
                <VideoSlider
                  videos={chunk}
                  category={`Lesezeichen ${index + 1}`}
                  bookmarkedVideos={bookmarkedVideos}
                  onBookmarkToggle={handleBookmarkToggle}
                />
                <Divider sx={{ backgroundColor: '#3f4b54', my: 4 }} />
              </Box>
            ))
          ) : (
            <Typography>Keine Videos mit Lesezeichen vorhanden.</Typography>
          )}
        </Box>
      </TabPanel>
    </Box>
  );
};

export default VideoStage;
