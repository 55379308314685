import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { People as PeopleIcon, VideoLibrary as VideoIcon, PersonAdd as PersonAddIcon, Group as GroupIcon, ShowChart as ChartIcon } from '@mui/icons-material';
import CountUp from 'react-countup'; // Import CountUp für animiertes Zählen
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { getFirestore, collection, getDocs } from 'firebase/firestore'; // Import Firebase Firestore Funktionen

// Chart.js registrieren
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Farben und Styles
const gold = '#d5bc8b';
const blue = '#252833';  // Original-Blau
const lighterBlue = '#303544'; // Ein wenig helleres Blau als Hintergrund

// Beispiel-Daten für die Charts
const mockActiveUsersData = {
  labels: Array.from({ length: 30 }, (_, i) => `Tag ${i + 1}`), // Letzte 30 Tage
  datasets: [
    {
      label: 'Aktive Nutzer pro Tag',
      data: [120, 130, 115, 140, 150, 135, 160, 170, 180, 175, 190, 200, 210, 195, 205, 210, 215, 230, 240, 250, 260, 255, 270, 280, 290, 300, 310, 320, 330, 340],
      borderColor: gold,
      backgroundColor: 'rgba(213, 188, 139, 0.2)',
    },
  ],
};

const mockNewRegistrationsData = {
  labels: Array.from({ length: 30 }, (_, i) => `Tag ${i + 1}`), // Letzte 30 Tage
  datasets: [
    {
      label: 'Neuanmeldungen pro Tag',
      data: [50, 60, 55, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180, 185, 190, 195],
      borderColor: gold,
      backgroundColor: 'rgba(213, 188, 139, 0.2)',
    },
  ],
};

const mockRevenueData = {
  labels: ['Woche 1', 'Woche 2', 'Woche 3', 'Woche 4'],
  datasets: [
    {
      label: 'Umsatz (tatsächlich)',
      data: [5000, 7000, 8000, 9000],
      borderColor: gold,
      backgroundColor: 'rgba(213, 188, 139, 0.2)',
    },
    {
      label: 'Umsatz (Plan)',
      data: [6000, 7000, 8500, 9500],
      borderColor: 'rgba(54, 162, 235, 1)',
      backgroundColor: 'rgba(54, 162, 235, 0.2)',
    },
  ],
};

// Dashboard-Komponente
const AdminDashboard_Chart = () => {
  const [totalMembers, setTotalMembers] = useState(0); // Anzahl Mitglieder mit positivem amount_brutto
  const [totalRevenue, setTotalRevenue] = useState(0); // Summe aller amount_brutto
  const [videosCount, setVideosCount] = useState(253); // Beispiel: Anzahl Videos
  const [dailyActiveUsers, setDailyActiveUsers] = useState(352); // Beispiel: Täglich angemeldete Nutzer
  const [growthPercentage, setGrowthPercentage] = useState(10); // Beispiel: Mitgliederzuwachs in %

  useEffect(() => {
    const fetchUserData = async () => {
      const db = getFirestore();
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);

      let activeMembersCount = 0;
      let totalAmountBrutto = 0;

      usersSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.amount_brutto && data.amount_brutto > 0) {
          activeMembersCount++;
          totalAmountBrutto += data.amount_brutto;
        }
      });

      setTotalMembers(activeMembersCount);
      setTotalRevenue(totalAmountBrutto);
    };

    fetchUserData();
  }, []);

  // Funktion zum Rendern der KPI-Boxen mit CountUp-Animation
  const renderStatBox = (title, value, icon) => (
    <Paper elevation={3} sx={{ 
      p: 2, 
      backgroundColor: lighterBlue,  // Hellere Blautönung
      color: 'white', 
      display: 'flex', 
      alignItems: 'center', 
      height: 160,  // Höhere Boxen
      minWidth: 220,  // Breitere Boxen
      border: `1px solid ${gold}`,  // Goldener Rahmen
    }}>
      <Box sx={{ mr: 3 }}> {/* Mehr Platz für größere Symbole */}
        {icon}
      </Box>
      <Box>
        <Typography variant="subtitle1" sx={{ color: 'white', fontSize: '1rem' }}>{title}</Typography>
        <Typography variant="h3" sx={{ color: 'white', fontWeight: 400 }}> {/* Weniger fett */}
          {/* CountUp-Animation */}
          <CountUp end={value} duration={2.5} />
          {title === 'Bruttoumsatz' && ' €'} {/* Euro-Symbol nur für Bruttoumsatz */}
        </Typography>
      </Box>
    </Paper>
  );

  return (
    <Box sx={{
        p: 3,
        backgroundColor: 'rgba(255, 255, 255, 0.4)', // Halbtransparenter weißer Hintergrund
        borderRadius: '8px', // Optional: Abgerundete Ecken für den Container
        minHeight: '100vh', // Der Hintergrund geht über die gesamte Höhe des Bildschirms
    }}>
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom color="white">Admin Dashboard</Typography>

        {/* Kennzahlen-Kästchen - Eine Zeile */}
        <Grid container spacing={1} sx={{ justifyContent: 'space-between' }}>
          <Grid item xs={12} sm={6} md={2}>
            {renderStatBox('Mitgliederzuwachs (4 Wochen)', growthPercentage, <PersonAddIcon sx={{ fontSize: 50, color: gold }} />)} {/* Größeres Symbol */}
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            {renderStatBox('Aktive Mitglieder', totalMembers, <PeopleIcon sx={{ fontSize: 50, color: gold }} />)} {/* Größeres Symbol */}
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            {renderStatBox('Anzahl Videos', videosCount, <VideoIcon sx={{ fontSize: 50, color: gold }} />)} {/* Größeres Symbol */}
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            {renderStatBox('Täglich angemeldete Nutzer', dailyActiveUsers, <GroupIcon sx={{ fontSize: 50, color: gold }} />)} {/* Größeres Symbol */}
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            {renderStatBox('Bruttoumsatz', totalRevenue, <ChartIcon sx={{ fontSize: 50, color: gold }} />)} {/* Größeres Symbol */}
          </Grid>
        </Grid>

        {/* Grafiken - Eine Zeile */}
        <Grid container spacing={2} sx={{ mt: 3, justifyContent: 'space-between' }}>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 2, backgroundColor: lighterBlue, border: `1px solid ${gold}` }}>
              <Typography variant="h6" gutterBottom color="white">
                Aktive Nutzer pro Tag (Letzte 30 Tage)
              </Typography>
              <Line data={mockActiveUsersData} />
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 2, backgroundColor: lighterBlue, border: `1px solid ${gold}` }}>
              <Typography variant="h6" gutterBottom color="white">
                Neuanmeldungen pro Tag (Letzte30 Tage)
              </Typography>
              <Line data={mockNewRegistrationsData} />
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 2, backgroundColor: lighterBlue, border: `1px solid ${gold}` }}>
              <Typography variant="h6" gutterBottom color="white">
                Umsatzverlauf gegenüber Plan
              </Typography>
              <Line data={mockRevenueData} />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AdminDashboard_Chart;

