import React, { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';

// Firebase-Konfiguration aus der .env-Datei laden
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Firebase initialisieren
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const VideoUploadFromJson = () => {
  const [file, setFile] = useState(null);
  const [outputData, setOutputData] = useState('');

  // Handler für Dateiauswahl
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]); // Speichere die Datei im State
    }
  };

  // Upload-Handler
  const handleUpload = async () => {
    if (!file) {
      alert('Bitte wählen Sie eine Datei aus!');
      return;
    }

    const reader = new FileReader();

    // Wenn die Datei erfolgreich gelesen wurde
    reader.onload = async (event) => {
      const fileContent = event.target.result;

      if (typeof fileContent === 'string') {
        try {
          const jsonData = JSON.parse(fileContent); // JSON-Datei parsen
          const videosCollectionRef = collection(db, 'videos'); // Referenz zur Firestore-Collection 'videos'

          // Daten in Firestore einfügen
          for (let video of jsonData) {
            await addDoc(videosCollectionRef, video);
          }
          setOutputData('Alle Videos wurden erfolgreich hochgeladen!');
        } catch (error) {
          console.error('Fehler beim Hochladen der Daten: ', error);
          setOutputData('Fehler beim Hochladen der Daten.');
        }
      } else {
        setOutputData('Fehler beim Lesen der Datei.');
      }
    };

    reader.onerror = () => {
      setOutputData('Fehler beim Lesen der Datei.');
    };

    reader.readAsText(file);
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>JSON Video-Upload zu Firestore</h1>

      <div>
        <input type="file" accept=".json" onChange={handleFileChange} />
        <button onClick={handleUpload} style={{ padding: '10px 20px', marginLeft: '10px' }}>
          JSON hochladen
        </button>
      </div>

      <div style={{ marginTop: '20px' }}>
        <p>{outputData || 'Status wird hier angezeigt...'}</p>
      </div>
    </div>
  );
};

export default VideoUploadFromJson;
