import React from 'react';
import TestStepper from '../Einschätzungstest/TestStepper_main';  // Importiere die TestStepper-Komponente

const Einschätzungstest = () => {
  return (
    <div
      style={{
        backgroundImage: `url('/images/backgrounds/kth_fasern.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingTop: '20px',
        color: 'white',
        overflow: 'hidden',
      }}
    >
      <div style={{
          backgroundColor: 'rgba(37, 40, 51, 0.8)',
          padding: '30px',
          borderRadius: '8px',
          width: '100%', // Nehme volle Breite des übergeordneten Containers
          maxWidth: '1280px', // Setze die Maximalbreite auf 1280px für mehr Platz
          minHeight: '100vh',
          overflowY: 'auto',
      }}>
        <h1>Einschätzungstest</h1>
        {/* TestStepper anzeigen */}
        <TestStepper />
      </div>
    </div>
  );
};

export default Einschätzungstest;
