// src/components/LogoutButton.js
import React from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi'; // Icon-Bibliothek, optional

const LogoutButton = () => {
  const auth = getAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate('/login'); // Navigiere zur Login-Seite nach dem Logout
      })
      .catch((error) => {
        console.error('Logout failed: ', error);
      });
  };

  return (
    <div
      className="fixed bottom-4 right-4 cursor-pointer bg-[#d5bc8b] text-white p-3 rounded-full shadow-lg hover:bg-[#bca26f] transition-colors duration-300"
      onClick={handleLogout}
    >
      <FiLogOut size={24} /> {/* Logout-Icon */}
    </div>
  );
};

export default LogoutButton;
