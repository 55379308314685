// src/components/Einschätzungstest/TestStepper.js

import React, { useState } from 'react';
import { Stepper, Step, StepLabel, Box, IconButton } from '@mui/material';
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import QuestionComponent1 from './QuestionComponent1';
import QuestionComponent2 from './QuestionComponent2';
import QuestionComponent3 from './QuestionComponent3';
import QuestionComponent4 from './QuestionComponent4';
import QuestionComponent5 from './QuestionComponent5';
import QuestionComponent6 from './QuestionComponent6';
import QuestionComponent7 from './QuestionComponent7';
import QuestionComponent8 from './QuestionComponent8';
import QuestionComponent9 from './QuestionComponent9';
import QuestionComponent10 from './QuestionComponent10';
import QuestionComponent11 from './QuestionComponent11';

const steps = [
  { label: 'Gesundheitszustand', component: QuestionComponent1 },
  { label: 'Reiten', component: QuestionComponent2 },
  { label: 'Test', component: QuestionComponent3 },
  { label: 'Test', component: QuestionComponent4 },
  { label: 'Test', component: QuestionComponent5 },
  { label: 'Test', component: QuestionComponent6 },
  { label: 'Test', component: QuestionComponent7 },
  { label: 'Test', component: QuestionComponent8 },
  { label: 'Test', component: QuestionComponent9 },
  { label: 'Test', component: QuestionComponent10 },
  { label: 'Test', component: QuestionComponent11 },

  // Weitere Fragen hier hinzufügen, wenn nötig
];

const TestStepper = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const saveAnswer = (answer) => {
    console.log('Antwort gespeichert:', answer);
  };

  const getStepContent = (stepIndex) => {
    const Component = steps[stepIndex].component;
    return <Component saveAnswer={saveAnswer} />;
  };

  return (
    <Box sx={{ width: '90%', mx: 'auto' }}> {/* Setzt die Breite auf 90% des übergeordneten Containers */}
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 2 }}>
        {getStepContent(activeStep)}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
        <IconButton
          onClick={handleBack}
          disabled={activeStep === 0}
          sx={{
            backgroundColor: '#252833',
            color: 'white',
            '&:hover': {
              backgroundColor: '#3f4b54',
            },
            width: 50,
            height: 50,
          }}
        >
          <ArrowBackIcon />
        </IconButton>

        <IconButton
          onClick={handleNext}
          disabled={activeStep === steps.length - 1}
          sx={{
            backgroundColor: '#252833',
            color: 'white',
            '&:hover': {
              backgroundColor: '#3f4b54',
            },
            width: 50,
            height: 50,
          }}
        >
          <ArrowForwardIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default TestStepper;
