import React from 'react';

const VimeoAuthorization = () => {
  const clientId = 'DEIN_CLIENT_ID';  // Vimeo-Client-ID
  const redirectUri = encodeURIComponent('https://your-app.com/callback');  // Deine Redirect-URL
  const state = 'secureRandomState';  // Optional: Ein Wert, um Anfragen zu identifizieren
  const scope = 'public private';  // Benötigte Berechtigungen

  const authUrl = `https://api.vimeo.com/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`;

  const handleAuthorize = () => {
    window.location.href = authUrl;  // Leitet den Benutzer zur Vimeo-Autorisierungsseite weiter
  };

  return (
    <div>
      <h1>Vimeo Autorisierung</h1>
      <button onClick={handleAuthorize}>Mit Vimeo verbinden</button>
    </div>
  );
};

export default VimeoAuthorization;
