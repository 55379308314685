import { createTheme } from '@mui/material/styles';

// Benutzerdefiniertes Material-UI-Theme erstellen
const theme = createTheme({
  palette: {
    primary: {
      main: '#252833',  // Blau als Primärfarbe
    },
    secondary: {
      main: '#d5bc8b',  // Gold als Sekundärfarbe
    },
  },
  typography: {
    fontFamily: 'Jost, sans-serif',  // Globale Schriftart Jost
  },
});

export default theme;
