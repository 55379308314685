import React, { useEffect } from 'react';
import axios from 'axios';

const OAuthCallback = () => {
  const handleCallback = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const authorizationCode = urlParams.get('code');  // Den Authorization Code aus der URL extrahieren

    const clientId = 'DEIN_CLIENT_ID';
    const clientSecret = 'DEIN_CLIENT_SECRET';
    const redirectUri = 'https://your-app.com/callback';  // Muss genau mit der registrierten Redirect-URI übereinstimmen

    try {
      const response = await axios.post('https://api.vimeo.com/oauth/access_token', {
        grant_type: 'authorization_code',
        code: authorizationCode,
        redirect_uri: redirectUri,
      }, {
        headers: {
          Authorization: `basic ${btoa(`${clientId}:${clientSecret}`)}`,  // Basisauthentifizierung
          'Content-Type': 'application/json',
          Accept: 'application/vnd.vimeo.*+json;version=3.4',
        },
      });

      const accessToken = response.data.access_token;  // Hier erhältst du das Access Token
      console.log('Access Token:', accessToken);
      // Speichere das Access Token für nachfolgende API-Anfragen
      localStorage.setItem('vimeoAccessToken', accessToken);
    } catch (error) {
      console.error('Fehler beim Abrufen des Access Tokens:', error);
    }
  };

  useEffect(() => {
    handleCallback();  // Führe die Token-Anfrage nach dem Redirect aus
  }, []);

  return (
    <div>
      <h1>Verarbeite OAuth Callback...</h1>
    </div>
  );
};

export default OAuthCallback;
