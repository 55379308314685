import React, { useState } from 'react';
import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Typography } from '@mui/material';
import { Dashboard as DashboardIcon, People as PeopleIcon, Person as PersonIcon, VideoLibrary as VideoLibraryIcon, Upload as UploadIcon, Stream as StreamIcon, PlayArrow as PlayArrowIcon, Security as SecurityIcon, PostAdd as PostAddIcon, Settings as SettingsIcon, Email as EmailIcon, SupportAgent as SupportAgentIcon, Assignment as AssignmentIcon } from '@mui/icons-material'; 
import { styled } from '@mui/system';

import UserManagementTable from '../../Administration/UserManagement_Table';  
import ZoomAdminPanel from '../../Administration/ZoomAdminPanel';  
import VideoManagementTable from '../../Administration/VideoManagement_Table';  
import AdminDashboardChart from '../../Administration/AdminDashboard_Chart';  
import ProductManagement from '../../Administration/ProductManagement';
import ThumbnailUpdate from '../../Administration/ThumnailUpdate';


// Erstelle ein einfaches Dashboard als Standardansicht
const DefaultDashboard = () => {
  return (
    <Box>
      <Typography variant="h4">Administrator-Dashboard</Typography>
      <AdminDashboardChart />
    </Box>
  );
};

const drawerWidth = 240;

const DrawerContainer = styled(Box)({
  display: 'flex',
});

// Styled ListItem mit korrekt verarbeitetem `button` Attribut
const AnimatedListItem = styled(({ selected, ...other }) => (
  <ListItem {...other} />
))(({ selected }) => ({
  margin: '4px 0',
  transition: '0.3s',
  backgroundColor: selected ? '#d5bc8b' : 'transparent',
  color: selected ? '#fff' : 'inherit',
  '&:hover': {
    backgroundColor: '#3f4b54',
    transform: 'scale(1.05)',
    color: '#fff',
  },
}));

const AdminDashboard = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const renderContent = () => {
    switch (selectedIndex) {
      case 0:
        return <DefaultDashboard />;
      case 1:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Benutzerverwaltung
            </Typography>
         <UserManagementTable />
          </>
        );
      case 2:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Administratoren
            </Typography>
            <Typography>Administratoren Inhalt hier</Typography>
          </>
        );
      case 3:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Livestream Planung
            </Typography>
            <Typography>Planung Inhalt hier</Typography>
          </>
        );
      case 4:
        return <ZoomAdminPanel />;
      case 5:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Videoverwaltung
            </Typography>
            <VideoManagementTable />
          </>
        );
      case 6:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Video-Upload
            </Typography>
            <ThumbnailUpdate />
          </>
        );
      case 7:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Benutzerrollen
            </Typography>
            <Typography>Benutzerrollen Inhalt hier</Typography>
          </>
        );
      case 8:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Beiträge
            </Typography>
            <Typography>Beiträge Inhalt hier</Typography>
          </>
        );
      case 9:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Mitarbeiterverwaltung
            </Typography>
            <ProductManagement />
          </>
        );
      case 10:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Emails
            </Typography>
            <Typography>Emails Inhalt hier</Typography>
          </>
        );
      case 11:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Ticketsystem
            </Typography>
            <Typography>Ticketsystem Inhalt hier</Typography>
          </>
        );
      case 12:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Einstellungen
            </Typography>
            <Typography>Einstellungen Inhalt hier</Typography>
          </>
        );
      default:
        return <Typography>Inhalt nicht gefunden</Typography>;
    }
  };

  const drawer = (
    <div>
      <Divider />
      {/* Dashboard Button */}
      <List>
        <AnimatedListItem
          button
          selected={selectedIndex === 0}
          onClick={(event) => handleListItemClick(event, 0)}
        >
          <ListItemIcon>
            <DashboardIcon sx={{ color: selectedIndex === 0 ? '#fff' : '#d5bc8b' }} />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </AnimatedListItem>
      </List>

      <Divider />

      {/* Benutzerverwaltung */}
      <Typography variant="h6" sx={{ padding: '16px', color: '#fff' }}>
        Benutzer
      </Typography>
      <List>
        <AnimatedListItem
          button
          selected={selectedIndex === 1}
          onClick={(event) => handleListItemClick(event, 1)}
        >
          <ListItemIcon>
            <PeopleIcon sx={{ color: selectedIndex === 1 ? '#fff' : '#d5bc8b' }} />
          </ListItemIcon>
          <ListItemText primary="Benutzerverwaltung" />
        </AnimatedListItem>
        <AnimatedListItem
          button
          selected={selectedIndex === 2}
          onClick={(event) => handleListItemClick(event, 2)}
        >
          <ListItemIcon>
            <PersonIcon sx={{ color: selectedIndex === 2 ? '#fff' : '#d5bc8b' }} />
          </ListItemIcon>
          <ListItemText primary="Administratoren" />
        </AnimatedListItem>
      </List>

      <Divider />

      {/* Livestreams */}
      <Typography variant="h6" sx={{ padding: '16px', color: '#fff' }}>
        Livestreams
      </Typography>
      <List>
        <AnimatedListItem
          button
          selected={selectedIndex === 3}
          onClick={(event) => handleListItemClick(event, 3)}
        >
          <ListItemIcon>
            <StreamIcon sx={{ color: selectedIndex === 3 ? '#fff' : '#d5bc8b' }} />
          </ListItemIcon>
          <ListItemText primary="Planung" />
        </AnimatedListItem>
        <AnimatedListItem
          button
          selected={selectedIndex === 4}
          onClick={(event) => handleListItemClick(event, 4)}
        >
          <ListItemIcon>
            <PlayArrowIcon sx={{ color: selectedIndex === 4 ? '#fff' : '#d5bc8b' }} />
          </ListItemIcon>
          <ListItemText primary="Live-Gehen" />
        </AnimatedListItem>
      </List>

      <Divider />

      {/* Lernplattform */}
      <Typography variant="h6" sx={{ padding: '16px', color: '#fff' }}>
        Lernplattform
      </Typography>
      <List>
        <AnimatedListItem
          button
          selected={selectedIndex === 5}
          onClick={(event) => handleListItemClick(event, 5)}
        >
          <ListItemIcon>
            <VideoLibraryIcon sx={{ color: selectedIndex === 5 ? '#fff' : '#d5bc8b' }} />
          </ListItemIcon>
          <ListItemText primary="Videoverwaltung" />
        </AnimatedListItem>
        <AnimatedListItem
          button
          selected={selectedIndex === 6}
          onClick={(event) => handleListItemClick(event, 6)}
        >
          <ListItemIcon>
            <UploadIcon sx={{ color: selectedIndex === 6 ? '#fff' : '#d5bc8b' }} />
          </ListItemIcon>
          <ListItemText primary="Video-Upload" />
        </AnimatedListItem>
      </List>

      <Divider />

      {/* Community */}
      <Typography variant="h6" sx={{ padding: '16px', color: '#fff' }}>
        Community
      </Typography>
      <List>
        <AnimatedListItem
          button
          selected={selectedIndex === 7}
          onClick={(event) => handleListItemClick(event, 7)}
        >
          <ListItemIcon>
            <SecurityIcon sx={{ color: selectedIndex === 7 ? '#fff' : '#d5bc8b' }} />
          </ListItemIcon>
          <ListItemText primary="Benutzerrollen" />
        </AnimatedListItem>
        <AnimatedListItem
          button
          selected={selectedIndex === 8}
          onClick={(event) => handleListItemClick(event, 8)}
        >
          <ListItemIcon>
            <PostAddIcon sx={{ color: selectedIndex === 8 ? '#fff' : '#d5bc8b' }} />
          </ListItemIcon>
          <ListItemText primary="Beiträge" />
        </AnimatedListItem>
      </List>

      <Divider />

      {/* Kundenservice */}
      <Typography variant="h6" sx={{ padding: '16px', color: '#fff' }}>
        Kundenservice
      </Typography>
      <List>
        <AnimatedListItem
          button
          selected={selectedIndex === 9}
          onClick={(event) => handleListItemClick(event, 9)}
        >
          <ListItemIcon>
            <SupportAgentIcon sx={{ color: selectedIndex === 9 ? '#fff' : '#d5bc8b' }} />
          </ListItemIcon>
          <ListItemText primary="Mitarbeiterverwaltung" />
        </AnimatedListItem>
        <AnimatedListItem
          button
          selected={selectedIndex === 10}
          onClick={(event) => handleListItemClick(event, 10)}
        >
          <ListItemIcon>
            <EmailIcon sx={{ color: selectedIndex === 10 ? '#fff' : '#d5bc8b' }} />
          </ListItemIcon>
          <ListItemText primary="Emails" />
        </AnimatedListItem>
        <AnimatedListItem
          button
          selected={selectedIndex === 11}
          onClick={(event) => handleListItemClick(event, 11)}
        >
          <ListItemIcon>
            <AssignmentIcon sx={{ color: selectedIndex === 11 ? '#fff' : '#d5bc8b' }} />
          </ListItemIcon>
          <ListItemText primary="Ticketsystem" />
        </AnimatedListItem>
      </List>

   
    </div>
  );

  return (
    <DrawerContainer
      sx={{
        backgroundImage: `url('/images/backgrounds/admin_background.webp')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        minHeight: '100vh',
      }}
    >
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
            marginTop: '69px',
            backgroundColor: '#252833',
            color: '#fff',
          },
        }}
      >
        {drawer}
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {renderContent()}
      </Box>
    </DrawerContainer>
  );
};

export default AdminDashboard;
