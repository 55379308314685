import React from 'react';
import { Box, Typography, Card, CardContent, CardMedia, IconButton } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import { Bookmark, BookmarkBorder } from '@mui/icons-material'; // Icons für das Lesezeichen
import { useNavigate } from 'react-router-dom'; // Für Navigation zu den Video-Seiten
import { styled } from '@mui/system';

// Styled component für gekürzten Text
const TruncatedText = styled(Typography)({
  display: '-webkit-box',
  WebkitLineClamp: 3, // Maximal 3 Zeilen
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const VideoSlider = ({ videos, category, bookmarkedVideos, onBookmarkToggle }) => {
  const navigate = useNavigate(); // Verwende useNavigate für die Navigation

  // Funktion, um zu einer Video-Seite zu navigieren
  const handleCardClick = (videoId) => {
    navigate(`/video/${videoId}`);
  };

  return (
    <Box sx={{ width: '100%', mt: 4 }}>
      <Typography variant="h4" component="h2" sx={{ mb: 2, color: '#d5bc8b' }}>
        {category}
      </Typography>
      <Swiper
        spaceBetween={10}
        slidesPerView={5}
        pagination={{ clickable: true }}
        navigation={true}
        modules={[Pagination, Navigation]}
        speed={500} // Smooth sliding
      >
        {videos.map((video) => (
          <SwiperSlide key={video.id}>
            <Card
              sx={{
                width: '100%',
                backgroundColor: '#252833',
                color: 'white',
                borderRadius: '8px',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                height: '100%',
                position: 'relative', // Ermöglicht absolute Positionierung von Icons
                transition: 'transform 0.3s ease-in-out', // Smooth hover transition
                '&:hover': {
                  transform: 'scale(1.05)', // Zoom-Effekt beim Hover
                  boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)', // Stärkere Schatten beim Hover
                },
              }}
            >
              {/* Lesezeichen-Icon */}
              <IconButton
                onClick={() => onBookmarkToggle(video)}
                sx={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  color: 'white',
                  zIndex: 10,
                }}
              >
                {bookmarkedVideos.includes(video.id) ? (
                  <Bookmark sx={{ color: '#d5bc8b' }} /> // Gefülltes Lesezeichen
                ) : (
                  <BookmarkBorder sx={{ color: '#d5bc8b' }} /> // Leeres Lesezeichen
                )}
              </IconButton>

              {/* Klickbare Card zum Abspielen des Videos */}
              <CardMedia
                component="img"
                height="140"
                image={video.thumbnail}
                alt={video.title}
                onClick={() => handleCardClick(video.id)}
                sx={{ cursor: 'pointer' }}
              />
              <CardContent
                sx={{
                  height: '150px', // Feste Höhe für den gesamten CardContent-Bereich
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    color: '#d5bc8b', // Goldene Farbe für den Titel
                    fontWeight: 'bold',
                    mb: 1,
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleCardClick(video.id)} // Titel ebenfalls anklickbar
                >
                  {video.title}
                </Typography>
                <TruncatedText
                  variant="body2"
                  sx={{ color: '#ffffff', textAlign: 'center' }}
                >
                  {video.description || ''}  {/* Beschreibung bleibt leer, falls nicht vorhanden */}
                </TruncatedText>
              </CardContent>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default VideoSlider;
