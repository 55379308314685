import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Avatar,
  Grid,
  Modal,
  TextField,
  Snackbar,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/system';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDocs, collection, updateDoc } from 'firebase/firestore';
import DeleteIcon from '@mui/icons-material/Delete';
import HorseIcon from '../../../../assets/icons/horse.svg'; // Stelle sicher, dass der Pfad korrekt ist
import HorseImageUploader from './utils/HorseImageUploader'; // Stelle sicher, dass der Pfad zur Komponente korrekt ist

const HorseManagementContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '20px',
  backgroundColor: '#252833',
  padding: '20px',
  borderRadius: '8px',
  opacity: 0.9,
});

const HorseCard = styled(Card)({
  minWidth: 275,
  margin: '10px',
  backgroundColor: '#252833',
  color: 'white',
});

const SubtleIconButton = styled(IconButton)(({ theme }) => ({
  color: '#A8C8E5', // Heller Blauton für den initialen Zustand
  transition: 'color 0.2s',
  '&:hover': {
    color: theme.palette.error.main, // Rot für den Hover-Zustand
  },
}));

const MyHorses = () => {
  const auth = getAuth();
  const db = getFirestore();
  const [horses, setHorses] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [newHorse, setNewHorse] = useState({ name: '', breed: '', age: '', id: '' });
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [selectedHorseId, setSelectedHorseId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deletedHorse, setDeletedHorse] = useState(null);

  const fetchHorses = async () => {
    const user = auth.currentUser;
    if (user) {
      const horsesRef = collection(db, 'users', user.uid, 'horses');
      const snapshot = await getDocs(horsesRef);
      const horsesData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setHorses(horsesData.filter(horse => !horse.horse_deleted)); // Zeige nur nicht gelöschte Pferde an
    }
  };

  useEffect(() => {
    fetchHorses();
  }, [auth, db]);

  const handleDeleteHorse = async (id) => {
    const user = auth.currentUser;
    if (user) {
      const horseRef = doc(db, 'users', user.uid, 'horses', id);
      await updateDoc(horseRef, { horse_deleted: true });
      const updatedHorses = horses.filter(horse => horse.id !== id); // Aktualisiere die Liste
      setHorses(updatedHorses); // Update den Zustand
      setDeletedHorse(horses.find(horse => horse.id === id)); // Setze das gelöschte Pferd für die Snackbar
      setSnackbarOpen(true); // Snackbar anzeigen
    }
  };

  const handleUndoDelete = async () => {
    if (deletedHorse) {
      const user = auth.currentUser;
      const horseRef = doc(db, 'users', user.uid, 'horses', deletedHorse.id);
      await updateDoc(horseRef, { horse_deleted: false });
      setHorses([...horses, { ...deletedHorse, horse_deleted: false }]); // Wiederherstellen des Pferdes
      setSnackbarOpen(false); // Snackbar schließen
      setDeletedHorse(null); // Zurücksetzen des gelöschten Pferdes
    }
  };

  const handleAddHorse = async () => {
    const user = auth.currentUser;
    if (user && horses.length < 3) {
      const horseId = `horse_${Date.now()}`; // Generiere eine eindeutige ID für das Pferd
      await setDoc(doc(db, 'users', user.uid, 'horses', horseId), {
        ...newHorse,
        horse_deleted: false,
        id: horseId,
      });
      setNewHorse({ name: '', breed: '', age: '', id: '' }); // Zurücksetzen des Formulars
      setModalOpen(false);
      fetchHorses(); // Aktualisiere die Pferdeanzeige
    }
  };

  return (
    <HorseManagementContainer>
      <Typography variant="h4" sx={{ color: 'white', mb: 2 }}>Meine Pferde</Typography>
      <Grid container spacing={2}>
        {horses.length === 0 ? (
          <Typography variant="body1" sx={{ color: 'white' }}>Es sind noch keine Pferde angelegt. Bitte füge ein Pferd hinzu.</Typography>
        ) : (
          horses.map((horse) => (
            <Grid item xs={12} sm={6} md={4} key={horse.id}>
              <HorseCard>
                <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Avatar
                    alt={horse.name}
                    src={horse.profilePicturePath || '/path/to/placeholder/image.jpg'} // Platzhalterbild
                    sx={{ width: 150, height: 150, mb: 2, cursor: 'pointer' }}
                    onClick={() => {
                      setSelectedHorseId(horse.id);
                      setImageDialogOpen(true);
                    }}
                  />
                  <Typography variant="h5">{horse.name}</Typography>
                  <Typography variant="body2">Rasse: {horse.breed}</Typography>
                  <Typography variant="body2">Alter: {horse.age} Jahre</Typography>
                </CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                  <SubtleIconButton onClick={() => handleDeleteHorse(horse.id)}>
                    <DeleteIcon />
                  </SubtleIconButton>
                </Box>
              </HorseCard>
            </Grid>
          ))
        )}
        {/* Platz für ein neues Pferd */}
        {horses.length < 3 && (
          <Grid item xs={12} sm={6} md={4}>
            <HorseCard onClick={() => setModalOpen(true)} sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CardContent>
                <Typography variant="h5">+ Pferd hinzufügen</Typography>
              </CardContent>
            </HorseCard>
          </Grid>
        )}
      </Grid>

      {/* Modal zum Hinzufügen eines neuen Pferdes */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box sx={{ padding: 4, backgroundColor: '#252833', color: 'white' }}>
          <Typography variant="h6">Pferd hinzufügen</Typography>
          <TextField
            label="Pferdename"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            value={newHorse.name}
            onChange={(e) => setNewHorse({ ...newHorse, name: e.target.value })}
          />
          <TextField
            label="Rasse"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            value={newHorse.breed}
            onChange={(e) => setNewHorse({ ...newHorse, breed: e.target.value })}
          />
          <TextField
            label="Alter"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            value={newHorse.age}
            onChange={(e) => setNewHorse({ ...newHorse, age: e.target.value })}
          />
          <Button variant="contained" onClick={handleAddHorse} sx={{ color: 'black', backgroundColor: '#d5bc8b' }}>
            Hinzufügen
          </Button>
        </Box>
      </Modal>
      
      {/* Pferdebild-Uploader */}
      <HorseImageUploader 
        open={imageDialogOpen} 
        onClose={() => setImageDialogOpen(false)} 
        horseId={selectedHorseId} 
      />

           {/* Snackbar für Löschbenachrichtigung */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={HorseIcon} alt="Horse Icon" style={{ width: 24, height: 24, marginRight: '8px', color: '#d5bc8b' }} />
            {`Das Profil von ${deletedHorse ? deletedHorse.name : ''} wurde gelöscht. Um das Löschen rückgängig zu machen, klicke `}
          </Box>
        }
        action={
          <Button color="inherit" onClick={handleUndoDelete} sx={{ color: '#d5bc8b' }}>
            Hier
          </Button>
        }
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Snackbar immer mittig
        sx={{
          zIndex: 9999,
        }}
      />
    </HorseManagementContainer>
  );
};

export default MyHorses;

