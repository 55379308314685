import React, { useState } from 'react';
import axios from 'axios';
import { db, storage } from '../../config/firebase'; // Stelle sicher, dass Firebase korrekt importiert wird
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore'; // Firestore Funktionen importieren
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Für Firebase Storage

const ThumbnailUpdate = () => {
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(false);

  // Funktion zur korrekten Extraktion der Vimeo-ID
  const extractVimeoId = (vimeoUrl) => {
    // Prüfe, ob die URL korrekt ist und enthalte nur den numerischen Teil
    const regex = /vimeo\.com\/(\d+)/;  // Regex, um nur die numerische Video-ID zu extrahieren
    const match = vimeoUrl.match(regex);
    return match ? match[1] : null;  // Rückgabe der Video-ID oder null, falls keine gefunden wird
  };

  // Funktion zum Abrufen und Speichern von Thumbnails für alle Videos
  const fetchThumbnails = async () => {
    try {
      setLoading(true);
      setStatus((prev) => [...prev, 'Start API-Aufruf: ✅']);

      // Hier den korrekten Access Token eingeben
      const accessToken = '2024478842affbe99bb8ccdccebcdbea'; // Vimeo Access Token

      if (!accessToken) {
        setStatus((prev) => [...prev, '❌ Fehler: Kein Access Token erhalten']);
        setLoading(false);
        return;
      }

      setStatus((prev) => [...prev, 'Access Token erfolgreich abgerufen ✅']);

      // Abrufen aller Videos aus der Firestore-Datenbank
      const videosSnapshot = await getDocs(collection(db, 'videos')); // Firestore collection richtig aufrufen
      const videos = videosSnapshot.docs.map(doc => ({ id: doc.id, vimeoUrl: doc.data().vimeo_id }));

      // Prüfen, ob Videos gefunden wurden
      if (videos.length === 0) {
        setStatus((prev) => [...prev, '❌ Keine Videos in der Datenbank gefunden.']);
        setLoading(false);
        return;
      }

      // Für jedes Video Thumbnails abrufen und speichern
      for (const video of videos) {
        const vimeoId = extractVimeoId(video.vimeoUrl); // Vimeo-ID korrekt extrahieren

        if (!vimeoId) {
          setStatus((prev) => [...prev, `❌ Fehler: Vimeo ID konnte aus der URL ${video.vimeoUrl} nicht extrahiert werden.`]);
          continue;
        }

        try {
          // Hole das Video und seine Thumbnails von der Vimeo API
          const config = {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          };

          const response = await axios.get(`https://api.vimeo.com/videos/${vimeoId}?fields=uri,name,pictures`, config);
          
          if (response.data && response.data.pictures) {
            const thumbnailUrl = response.data.pictures.sizes[3].link; // Beispiel: Größe 640px Bild
            setStatus((prev) => [...prev, `Thumbnails für Video ${vimeoId} abgerufen: ${thumbnailUrl} ✅`]);

            // Hochladen des Thumbnails in Firebase Storage
            const responseImage = await axios.get(thumbnailUrl, { responseType: 'blob' });
            const storageRef = ref(storage, `thumbnails/${video.id}.jpg`); // Speicherort im Firebase Storage
            const snapshot = await uploadBytes(storageRef, responseImage.data);
            const downloadURL = await getDownloadURL(snapshot.ref);
            
            // Speichern der Thumbnail-URL in Firestore
            const videoDocRef = doc(db, 'videos', video.id); // Korrekte Referenz auf das Dokument
            await updateDoc(videoDocRef, {
              thumbnailUrl: downloadURL,
            });

            setStatus((prev) => [...prev, `Thumbnail für Video ${vimeoId} erfolgreich hochgeladen und in Firestore gespeichert ✅`]);
          } else {
            setStatus((prev) => [...prev, `❌ Fehler: Keine Thumbnails für Video ${vimeoId} gefunden.`]);
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            setStatus((prev) => [...prev, `❌ Fehler: Request failed with status code 404 - Video ${vimeoId} nicht gefunden`]);
          } else if (error.response && error.response.status === 401) {
            setStatus((prev) => [...prev, `❌ Fehler: Unauthorized - Access Token ist ungültig`]);
          } else {
            setStatus((prev) => [...prev, `❌ Fehler beim Abrufen des Thumbnails für Video ${vimeoId}: ${error.message}`]);
          }
        }
      }
    } catch (error) {
      console.error('Fehler:', error);
      setStatus((prev) => [...prev, `❌ Fehler: ${error.message}`]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Thumbnail Update Debugger</h1>
      <button onClick={fetchThumbnails} disabled={loading}>
        {loading ? 'Lädt...' : 'Thumbnails für alle Videos aktualisieren'}
      </button>
      <ul>
        {status.map((msg, index) => (
          <li key={index}>{msg}</li>
        ))}
      </ul>
    </div>
  );
};

export default ThumbnailUpdate;
