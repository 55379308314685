import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Typography, Tooltip, Avatar, CircularProgress } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material'; // Icons für Aktionen
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebase';  // Importiere Firestore-Instanz
import axios from 'axios'; // Für den Abruf von Vimeo-Thumbnails

const VideoManagementTable = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  // Funktion zum Abrufen der Vimeo-Thumbnail-URL
  const fetchVimeoThumbnail = async (vimeoId) => {
    try {
      const response = await axios.get(`https://vimeo.com/api/v2/video/${vimeoId}.json`);
      const thumbnailUrl = response.data[0].thumbnail_large;  // Größeres Thumbnail auswählen
      return thumbnailUrl;
    } catch (error) {
      console.error(`Fehler beim Abrufen des Thumbnails für Vimeo-ID: ${vimeoId}`, error);
      return null;
    }
  };

  // Funktion zum Abrufen der Videosammlung aus Firestore
  const fetchVideoData = async () => {
    try {
      const videosRef = collection(db, 'videos');  // Firestore-Sammlung 'videos'
      const videoSnapshot = await getDocs(videosRef);
      const videoList = await Promise.all(
        videoSnapshot.docs.map(async (doc) => {
          const videoData = doc.data();
          const thumbnail = await fetchVimeoThumbnail(videoData.vimeo_id);  // Vimeo-Thumbnail abrufen
          return {
            id: doc.id,
            ...videoData,
            thumbnail,  // Füge das Thumbnail zum Video-Objekt hinzu
          };
        })
      );
      setVideos(videoList);
    } catch (error) {
      console.error("Fehler beim Abrufen der Videos:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchVideoData();  // Abrufen der Videos bei Laden der Komponente
  }, []);

  const handleEdit = (videoId) => {
    console.log("Video bearbeiten:", videoId);
    // Logik zum Bearbeiten des Videos hinzufügen
  };

  const handleDelete = (videoId) => {
    console.log("Video löschen:", videoId);
    // Logik zum Löschen des Videos hinzufügen
  };

  // Wenn die Daten noch geladen werden, zeige die goldene Ladeanimation an
  if (loading) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',  // Volle Höhe des Bildschirms
        backgroundColor: '#f5f5f5',  // Optional: leicht grauer Hintergrund
      }}>
        <CircularProgress sx={{ color: '#d5bc8b', width: 80, height: 80 }} />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Videoverwaltung
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell>Thumbnail</TableCell>
              <TableCell>Titel</TableCell>
              <TableCell>Serie</TableCell>
              <TableCell>Beschreibung</TableCell>
              <TableCell sx={{ maxWidth: 100 }}>Themenbereiche</TableCell>
              <TableCell>Vimeo ID</TableCell>
              <TableCell>Aktionen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {videos.map((video) => (
              <TableRow
                key={video.id}
                sx={{
                  '&:hover': {
                    backgroundColor: '#d5bc8b',  // Hover-Effekt
                    'svg': { color: '#ffffff' },  // Symbole werden beim Hover weiß
                  },
                  height: 48,  // Zeilenhöhe
                  fontSize: '1.1rem',  // Schriftgröße
                }}
              >
                {/* Vimeo Thumbnail */}
                <TableCell>
                  <Avatar 
                    src={video.thumbnail} 
                    alt={`Thumbnail for ${video.titel}`} 
                    variant="square" 
                    sx={{ width: 64, height: 64 }} 
                  />
                </TableCell>

                {/* Titel des Videos */}
                <TableCell sx={{ fontSize: '1.1rem' }}>{video.titel || 'Kein Titel verfügbar'}</TableCell>

                {/* Serie des Videos */}
                <TableCell sx={{ fontSize: '1.1rem' }}>{video.serie || 'Keine Serie verfügbar'}</TableCell>

                {/* Beschreibung des Videos, auf 3 Zeilen begrenzt */}
                <TableCell sx={{
                  fontSize: '1.1rem',
                  maxWidth: 300,  // Maximale Breite der Zelle (optional)
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,  // Auf 3 Zeilen begrenzt
                  WebkitBoxOrient: 'vertical',
                  whiteSpace: 'normal',
                }}>
                  {video.text || 'Keine Beschreibung verfügbar'}
                </TableCell>

                {/* Themenbereiche, auf schmale Breite beschränkt */}
                <TableCell sx={{
                  fontSize: '1.1rem',
                  maxWidth: 100,  // Schmale Breite für die Themenbereiche
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}>
                  {video.themenbereiche ? video.themenbereiche.join(', ') : 'Keine Themenbereiche verfügbar'}
                </TableCell>

                {/* Vimeo ID */}
                <TableCell sx={{ fontSize: '1.1rem' }}>{video.vimeo_id || 'Keine Vimeo ID verfügbar'}</TableCell>

                {/* Aktionen (Bearbeiten, Löschen) */}
                <TableCell>
                  <Tooltip title="Video bearbeiten">
                    <IconButton onClick={() => handleEdit(video.id)} color="primary">
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Video löschen">
                    <IconButton onClick={() => handleDelete(video.id)} color="secondary">
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default VideoManagementTable;
