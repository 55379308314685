import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc, collection, addDoc, getDocs } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Player from '@vimeo/player';

const VideoPlayerPage = () => {
  const { videoId } = useParams(); // Holt die Video-ID aus der URL
  const [videoData, setVideoData] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null); // Benutzerstatus
  const playerContainerRef = useRef(null); // Ref für den Vimeo-Player

  const db = getFirestore();
  const auth = getAuth();

  // Überwache den Authentifizierungsstatus des Benutzers
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, [auth]);

  // Video-Daten aus der Firestore-Datenbank abrufen
  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const videoDocRef = doc(db, 'videos', videoId);  // Nutze videoId für den Dokumentenpfad
        const videoDoc = await getDoc(videoDocRef);

        if (videoDoc.exists()) {
          const data = videoDoc.data();
          setVideoData(data);
        } else {
          setError('Video nicht gefunden.');
        }
      } catch (err) {
        setError('Fehler beim Abrufen der Video-Daten.');
      } finally {
        setLoading(false);
      }
    };

    if (videoId) {
      fetchVideoData();
    }
  }, [db, videoId]);

  // Initialisiere den Vimeo-Player, sobald videoData verfügbar ist
  useEffect(() => {
    if (videoData && playerContainerRef.current) {
      const player = new Player(playerContainerRef.current, {
        url: videoData.vimeo_id,
        width: playerContainerRef.current.offsetWidth, // Hier die volle Breite des Containers setzen
      });

      player.on('play', function () {
        console.log('Das Video wird abgespielt');
      });
    }
  }, [videoData]);

  // Kommentare abrufen
  useEffect(() => {
    const fetchComments = async () => {
      try {
        const commentsCollection = collection(db, 'videos', videoId, 'comments');
        const commentsSnapshot = await getDocs(commentsCollection);

        const commentsList = commentsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setComments(commentsList);
      } catch (err) {
        setError('Fehler beim Abrufen der Kommentare.');
      }
    };

    if (videoId) {
      fetchComments();
    }
  }, [db, videoId]);

  // Kommentar hinzufügen
  const handleCommentSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      setError('Bitte melde dich an, um einen Kommentar zu posten.');
      return;
    }

    if (!newComment.trim()) {
      return;
    }

    try {
      const commentData = {
        text: newComment,
        userId: user.uid,
        userName: user.displayName || 'Unbekannter Benutzer',
        createdAt: new Date(), // Sicherstellen, dass createdAt gesetzt wird
      };

      const commentsCollection = collection(db, 'videos', videoId, 'comments');
      await addDoc(commentsCollection, commentData);

      setComments((prevComments) => [...prevComments, commentData]);
      setNewComment('');
    } catch (err) {
      setError('Fehler beim Hinzufügen des Kommentars.');
    }
  };

  // Zeige Ladespinner oder Fehler an, falls es einen gibt
  if (loading) {
    return <div>Lädt...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div
      style={{
        backgroundImage: 'url(/images/backgrounds/kth_fasern.png)', // Hintergrundbild für die gesamte Seite
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        minHeight: '100vh',
        padding: '20px',
      }}
    >
      <div
        style={{
          backgroundColor: '#252833', // Container Hintergrundfarbe
          border: '1px solid #3f4b54', // Blauer Rahmen
          color: '#d5bc8b', // Textfarbe
          padding: '20px',
          maxWidth: '70%',
          margin: '0 auto',
        }}
      >
        {/* Videotitel */}
        {videoData && (
          <div>
            <h1>{videoData.title}</h1>
            <div
              style={{
                position: 'relative',
                width: '100%', 
                paddingBottom: '56.25%', // Verhältnis 16:9 für das Video
                marginBottom: '20px',
              }}
            >
              {/* Vimeo-Player-Container */}
              <div
                ref={playerContainerRef}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
              ></div>
            </div>
            <p>{videoData.description}</p>
          </div>
        )}

        {/* Kommentare anzeigen */}
        <div>
          <h2>Kommentare</h2>
          {comments.length > 0 ? (
            comments.map((comment) => (
              <div
                key={comment.id}
                style={{
                  marginBottom: '10px',
                  borderBottom: '1px solid #d5bc8b',
                  paddingBottom: '10px',
                }}
              >
                <p>
                  <strong>{comment.userName}</strong> schrieb:
                </p>
                <p>{comment.text}</p>
                <p style={{ fontSize: '0.8rem', color: '#999' }}>
                  {comment.createdAt
                    ? new Date(comment.createdAt.seconds * 1000).toLocaleString()
                    : 'Zeitpunkt unbekannt'}
                </p>
              </div>
            ))
          ) : (
            <p>Sei der Erste, der ein Kommentar hinterlässt...</p>
          )}
        </div>

        {/* Kommentar-Formular */}
        {user ? (
          <form onSubmit={handleCommentSubmit} style={{ marginTop: '20px' }}>
            <textarea
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Schreibe einen Kommentar..."
              rows="4"
              style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
            />
            <button
              type="submit"
              style={{
                padding: '10px 20px',
                backgroundColor: '#d5bc8b',
                color: '#252833',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              Kommentar hinzufügen
            </button>
          </form>
        ) : (
          <p>Bitte melde dich an, um einen Kommentar zu hinterlassen.</p>
        )}
      </div>
    </div>
  );
};

export default VideoPlayerPage;
