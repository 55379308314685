// src/components/pages/Login.js
import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import '../../styles/Login.css'; // CSS-Datei für zusätzliche Styles

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const auth = getAuth(); // Initialisiere die Authentifizierungsinstanz

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Anmelden mit E-Mail und Passwort
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard'); // Weiterleitung zur Dashboard-Seite nach erfolgreichem Login
    } catch (err) {
      setError(err.message); // Fehleranzeige bei Anmeldefehlern
    }
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100">
      {/* Hintergrundbild */}
      <div className="absolute inset-0">
        <img
          className="h-full w-full object-cover"
          src="/images/backgrounds/login_background.jpg" // Stelle sicher, dass das Bild im public/images Ordner vorhanden ist
          alt="Background"
        />
      </div>
      
      {/* Login-Formular */}
      <div className="relative z-10 flex flex-col justify-center w-full max-w-md p-8 bg-[#252833] bg-opacity-80 shadow-lg rounded-lg">
        <div className="flex justify-center mb-4">
          <img src="/images/logos/kth_logo_horse.png" alt="Logo" className="w-50" /> {/* Stelle sicher, dass das Bild existiert */}
        </div>
 
        
        {/* Anmeldeformular */}
        <form onSubmit={handleLogin} className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-gray-300">E-mail</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
              placeholder="Gib deine Email ein"
              required
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-gray-300">Passwort</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
              placeholder="********"
              required
            />
          </div>
          <div className="flex justify-between items-center text-sm">
            <label className="flex items-center text-gray-300">
            
            </label>
            <a href="/forgot-password" className="text-white hover:text-yellow-600">Ich habe mein Passwort vergessen</a>
          </div>
          {error && <p className="text-red-500 text-sm">{error}</p>}
          <button type="submit" className="w-full py-3 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition duration-300">
            Log in
          </button>
          <div className="text-center mt-4">
            <p className="text-gray-300">
              noch keinen Account? <a href="/register" className="text-yellow-500 hover:text-yellow-600">Registriere dich hier</a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
