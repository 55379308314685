import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Importieren der Seiten
import Login from './components/pages/Login';
import Dashboard from './components/pages/Dashboard';
import Einschaetzungstest from './components/pages/Einschaetzungstest';
import AdminDashboard from './components/pages/Administrator/AdminDashboard';
import Dbtest from './components/pages/Dbtest';
import UserProfile from './components/pages/Community/UserProfile';
import JsonImport from './components/pages/Administrator/Jsonimport';
import VideoStage from './components/pages/Lernplattform/VideoStage';
import VideoPlayerPage from './components/pages/Lernplattform/VideoPlayerPage'; // Neue Seite für den Video-Player
import VimeoAuthorization from './components/pages/VimeoAuthorization';
import OAuthCallback from './components/pages/OAuthCallback';
import ThumbnailsUpdate from './components/Administration/ThumnailUpdate';

// Importieren der Komponenten
import Navbar from './components/layout/navbar'; // Beispiel für eine Navbar
import LogoutButton from './components/LogoutButton';
import ProtectedRoute from './components/ProtectedRoute';

import './index.css';  // Tailwind CSS hier importieren

const App = () => {
  const [user, setUser] = useState(null); // Zustand, um den aktuellen Benutzer zu speichern
  const [loading, setLoading] = useState(true); // Zustand für Ladespinner oder -anzeige

  useEffect(() => {
    const auth = getAuth(); // Authentifizierungsinstanz
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Benutzer ist eingeloggt
        setUser(user);
      } else {
        // Benutzer ist nicht eingeloggt
        setUser(null);
      }
      setLoading(false); // Ladevorgang beendet
    });

    return () => unsubscribe(); // Bereinigung bei Komponentenausblendung
  }, []);

  // Ladespinner oder -anzeige während des Ladevorgangs
  if (loading) {
    return <div>Loading...</div>;
  }

  // Bestimme, ob die Navbar angezeigt werden soll (nicht auf Login und CheckoutPrepare)
  const shouldShowNavbar = window.location.pathname !== '/login' && window.location.pathname !== '/CheckoutPrepare';

  return (
    <Router>
      <div className="app">
        {/* Zeige die Navbar auf allen Seiten außer Login und CheckoutPrepare */}
        {shouldShowNavbar && (
          <Navbar />
        )}

        <Routes>
          {/* Login-Seite */}
          <Route path="/login" element={<Login />} />
          <Route path="/authorize" element={<VimeoAuthorization />} />
          <Route path="/callback" element={<OAuthCallback />} />
          <Route path="/update-thumbnails" element={<ThumbnailsUpdate />} />

          {/* Geschützte Routen */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Einschaetzungstest"
            element={
              <ProtectedRoute>
                <Einschaetzungstest />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dbtest"
            element={
              <ProtectedRoute>
                <Dbtest />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <UserProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jsonimport"
            element={
              <ProtectedRoute>
                <JsonImport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/videostage"
            element={
              <ProtectedRoute>
                <VideoStage />
              </ProtectedRoute>
            }
          />

          {/* Route für den Video-Player */}
          <Route
            path="/video/:videoId"
            element={
              <ProtectedRoute>
                <VideoPlayerPage />
              </ProtectedRoute>
            }
          />

          {/* Standardpfad: Wenn der Benutzer eingeloggt ist, zum Dashboard weiterleiten, andernfalls zum Login */}
          <Route
            path="/"
            element={user ? <Navigate to="/dashboard" /> : <Navigate to="/login" />}
          />

          {/* Fallback für ungültige Routen */}
          <Route path="*" element={<Navigate to={user ? "/dashboard" : "/login"} />} />
        </Routes>

        {/* Zeige den Logout-Button nur, wenn der Benutzer eingeloggt ist */}
        {user && <LogoutButton />}
      </div>
    </Router>
  );
};

export default App;
